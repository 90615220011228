<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2022-06-29 16:14:29
 * @LastEditTime: 2022-07-15 18:14:54
-->
<template>
	<div class="recordsAppeal">
		<div class="recordsAppeal-content">
			<el-tabs v-model="currentTab" @tab-click="changeTab">
				<el-tab-pane label="待处理" name="NOT_PROCESSED"></el-tab-pane>
				<el-tab-pane label="已处理" name="PROCESSED"></el-tab-pane>
			</el-tabs>
			<div class="recordsAppeal-table">
				<el-table :data="tableData" border>
					<el-table-column width="140px" prop="regNum" label="注册号">
					</el-table-column>
					<el-table-column width="100px" prop="name" label="姓名">
					</el-table-column>
					<el-table-column width="150px" prop="studentId" label="学号">
					</el-table-column>
					<el-table-column prop="collegeName" label="学院">
					</el-table-column>
					<el-table-column width="100px" prop="creditScore" label="信誉分">
					</el-table-column>
					<el-table-column width="100px" prop="deductionDetails" label="扣分详情">
						<template slot-scope="scope">
							<div class="table-action">
								<el-popover placement="top" width="400" trigger="click">
									<div class="table-action-records">
										<div class="action-records-item">
											<div class="records-item-step">
												<span class="item-step-val"
													:class="{'step-val-add': scope.row.deductionDetails.addAndSub == true, 'step-val-cut': scope.row.deductionDetails.addAndSub == false}">{{scope.row.deductionDetails.addAndSub == true?'+':scope.row.deductionDetails.addAndSub == false?'-':''}}{{scope.row.deductionDetails.fraction}}分</span>
											</div>
											<div class="records-item-row">
												<div class="records-item-col">
													<span class="records-item-label">活动ID：</span>
													<span
														class="records-item-value">{{scope.row.deductionDetails.activityId}}</span>
												</div>
												<div class="records-item-col">
													<span class="records-item-label">记录时间：</span>
													<span
														class="records-item-value">{{scope.row.deductionDetails.createTime}}</span>
												</div>
											</div>
											<div class="records-item-row">
												<div class="records-item-col">
													<span class="records-item-label">活动名称：</span>
													<span
														class="records-item-value">{{scope.row.deductionDetails.activityName}}</span>
												</div>
											</div>
											<div class="records-item-row">
												<div class="records-item-col">
													<span class="records-item-label">活动时间：</span>
													<span class="records-item-value">{{scope.row.activityTime}}</span>
												</div>
											</div>
											<div class="records-item-row">
												<div class="records-item-col">
													<span class="records-item-label">加分/减分原因：</span>
													<span
														class="records-item-value">{{scope.row.deductionDetails.reason}}{{scope.row.deductionDetails.reasonDescribe}}</span>
												</div>
											</div>
											<div class="records-item-row">
												<div class="records-item-col">
													<span class="records-item-label">备注：</span>
													<span
														class="records-item-value">{{scope.row.deductionDetails.remark}}</span>
												</div>
											</div>
											<div class="records-item-row">
												<div class="records-item-col">
													<span class="records-item-label">记录人：</span>
													<span
														class="records-item-value">{{scope.row.deductionDetails.recorder}}
													</span>
													<span
														v-if="scope.row.organization">({{scope.row.organization}})</span>
												</div>
											</div>
										</div>
									</div>
									<span slot="reference" @click="actionFn('detail', scope)">查看</span>
								</el-popover>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="appealReason" label="申诉原因">
					</el-table-column>
					<el-table-column width="180px" prop="appealImg" label="申诉图片">
						<template slot-scope="scope">
							<div class="action-imglist">
								<el-image v-for="(item, index) in scope.row.appealImg" :key="index" fit="cover"
									class="action-imglist-item" :src="item" :preview-src-list="[item]">
								</el-image>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="createTime" label="申诉时间">
					</el-table-column>
					<el-table-column v-if="currentTab == 'PROCESSED'" prop="status" label="状态">
						<template slot-scope="scope">
							<span style="color: #1CABB9;" v-if="scope.row.status == 1">已通过</span>
							<span style="color: #DF4635;" v-if="scope.row.status == 2">已拒绝</span>
						</template>
					</el-table-column>
					<el-table-column :prop="currentTab == 'NOT_PROCESSED'?'action':'createBy'"
						:label="currentTab == 'NOT_PROCESSED'?'操作':'处理人'">
						<template slot-scope="scope">
							<div v-if="currentTab == 'NOT_PROCESSED'" class="table-action">
								<span @click="actionFn('agree', scope)">通过</span>
								<el-divider direction="vertical"></el-divider>
								<span @click="actionFn('refeuse', scope)">拒绝</span>
							</div>
							<span v-else>{{scope.row.createBy}}</span>
						</template>
					</el-table-column>
					<el-table-column v-if="currentTab == 'PROCESSED'" prop="createTime" label="处理时间">
						<template slot-scope="scope">
							<span>{{scope.row.updateTime}}</span>
						</template>
					</el-table-column>
				</el-table>
				<div class="common-pageNation">
					<el-pagination background @current-change="currentChange" :current-page.sync="pageIndex"
						:page-size="pageSize" layout="total, prev, pager, next" :total="totalCount">
					</el-pagination>
				</div>
			</div>
		</div>
		<refuse-reason :isOpen="dialogVisible" @cancle="closeDialog" @submit="submitDialog" />
	</div>
</template>

<script>
	import refuseReason from "./components/refuseReason.vue";
	import {
		listCutRecordsApply,
		verifyCutRecordsApply,
	} from "@/api/student";
	export default {
		components: {
			refuseReason
		},
		data() {
			return {
				currentTab: 'NOT_PROCESSED',
				tableData: [],
				totalCount: 0,
				pageIndex: 1,
				pageSize: 10,
				// recodsDetailList: [{
				// 	id: 10000,
				// 	createTime: "2022-07-07 23:59:59",
				// 	name: "莫干山羽毛球大赛志愿者活动",
				// 	startTime: "2022-07-15",
				// 	reason: "杭州荣鼎科技是一家现代服务型的科技企业，专注于企业服务领域，致力于成为100万家企业的首选合作伙伴。荣鼎始终坚守让每一份信任不被辜负的使命，坚持全直营的互联网企业服务。旗下有IT技术服务、人力资源服务、职业教育服务、数字营销服务四大直营业务板块。拥有职通通APP和同行圈APP两大互联网平台品牌。",
				// 	mark: "表现很好",
				// 	user: "李飞（材料分会）",
				// 	type: 1,
				// 	records: 5
				// }, ],
				dialogVisible: false,
				applyVerifyId: "",
			};
		},
		mounted() {
			this.getCutRecordsApplyList();
		},
		methods: {
			changeTab(e) {
				this.currentTab = e.name
				this.pageIndex = 1;
				this.tableData = [];
				this.getCutRecordsApplyList();
			},
			getCutRecordsApplyList() {
				let params = {
					pageIndex: this.pageIndex,
					pageSize: this.pageSize,
					status: this.currentTab
				}
				listCutRecordsApply(params).then((res) => {
					if (res.code == 0) {
						res.data.forEach(item => {
							if (item.appealImg) {
								item.appealImg = item.appealImg.split(",")
							} else {
								item.appealImg = []
							}
						})
						this.tableData = res.data
						this.totalCount = res.page.totalCount * 1
					}
				})
			},
			currentChange(val) {
				this.pageIndex = val;
				this.tableData = [];
				this.getCutRecordsApplyList();
			},
			actionFn(type, scope) {
				switch (type) {
					case "agree":
						this.$confirm('确定通过此学生的扣分申诉吗?', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'error'
						}).then(() => {
							this.applyVerifyId = scope.row.id
							verifyCutRecordsApply(scope.row.id, {
								status: 1,
								id: scope.row.id
							}).then((res) => {
								if (res.code == 0) {
									this.$message({
										type: 'success',
										message: '扣分申诉已通过!'
									});
									this.pageIndex = 1;
									this.tableData = [];
									this.getCutRecordsApplyList();
								}
							})
						}).catch(() => {});
						break;
					case "refeuse":
						// this.dialogVisible = true
						this.$confirm('确定拒绝此学生的扣分申诉吗?', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'error'
						}).then(() => {
							this.applyVerifyId = scope.row.id
							verifyCutRecordsApply(scope.row.id, {
								status: 2,
								id: scope.row.id
							}).then((res) => {
								if (res.code == 0) {
									this.$message({
										type: 'success',
										message: '扣分申诉已拒绝!'
									});
									this.pageIndex = 1;
									this.tableData = [];
									this.getCutRecordsApplyList();
								}
							})
						}).catch(() => {});
						break;
				}
			},
			closeDialog() {
				this.dialogVisible = false
			},
			submitDialog() {},
		}
	};
</script>

<style lang="scss" scoped>
	/deep/.el-input__inner {
		height: 36px;
		border-radius: 0px 2px 2px 0px;
		border: 0;
	}

	.records-item-step {
		position: absolute;
		top: -6px;
		left: 5px !important;
		display: flex;
		align-items: center;
	}

	.recordsAppeal {
		.recordsAppeal-content {
			padding: 0 20px 20px 20px;

			.recordsAppeal-table {
				margin-top: 20px;

				.action-imglist {
					display: flex;
					align-items: center;
					flex-wrap: wrap;

					.action-imglist-item {
						width: 48px;
						height: 48px;
						margin: 0 4px 4px 0;
					}
				}
			}
		}
	}
</style>
